import { ToastContainer } from "react-toastify";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import { Route, Routes } from "react-router-dom";
import { Redirect } from "./components/Redirect/Redirect";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Main />
              <Footer />
              <ToastContainer />
            </>
          }
        />
        <Route path="/redirect" element={<Redirect />} />
      </Routes>
      <div id="pixelTagId"></div>
      <div id="pixelTag2Id"></div>
    </>
  );
}

export default App;
